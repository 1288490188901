<template>
  <!-- A card to display cube.js data -->
  <v-row>
    <!-- Pending -->
    <v-col cols="3" sm="6" md="3">
      <v-card
        :color="getStatusColor('Pending')"
        class="d-flex align-center p-3"
        dark
        tile
        :to="{
          name: 'active-orders',
          params: { toggle_id: getToggleId('Pending') },
        }"
      >
        <v-container grid-list-xs>
          <v-layout row wrap>
            <v-flex sm7 lg9 v-show="$vuetify.breakpoint.smAndUp">
              <v-flex xs12>
                <div class="display-1 bold">
                  <span v-if="pendingData">
                    {{ pendingData["Orders.count"] }}
                  </span>
                  <span v-else>0</span>
                </div>
              </v-flex>
              <v-flex xs12>
                <div class="bold">
                  {{
                    pendingData
                      ? formatCurrency(pendingData["Orders.lineItemTotal"])
                      : formatCurrency(0)
                  }}
                </div>
                <div class="uppercase bold">Pending</div>
              </v-flex>
            </v-flex>
            <v-flex xs12 lg3 sm5 class="text-center text-sm-end" align-self-end>
              <v-icon
                :size="$vuetify.breakpoint.smAndUp ? 60 : 40"
                color="white"
                :class="getStatusIcon('Pending')"
              >
                {{ getStatusIcon("Pending") }}
              </v-icon>
              <v-badge overlap v-if="$vuetify.breakpoint.xsOnly" class="pb-7">
                <span slot="badge">{{
                  pendingData ? pendingData["Orders.count"] : 0
                }}</span>
              </v-badge>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-col>
    <!-- Accepted -->
    <v-col cols="3" sm="6" md="3">
      <v-card
        :color="getStatusColor('Accepted')"
        class="d-flex align-center p-3"
        dark
        tile
        :to="{
          name: 'active-orders',
          params: { toggle_id: getToggleId('Accepted') },
        }"
      >
        <v-container grid-list-xs>
          <v-layout row wrap>
            <v-flex sm7 lg9 v-show="$vuetify.breakpoint.smAndUp">
              <v-flex xs12>
                <div class="display-1 bold">
                  <span v-if="acceptedData">
                    {{ acceptedData["Orders.count"] }}
                  </span>
                  <span v-else>0</span>
                </div>
              </v-flex>
              <v-flex xs12>
                <div class="bold">
                  {{
                    acceptedData
                      ? formatCurrency(acceptedData["Orders.lineItemTotal"])
                      : formatCurrency(0)
                  }}
                </div>
                <div class="uppercase bold">Accepted</div>
              </v-flex>
            </v-flex>
            <v-flex xs12 lg3 sm5 class="text-center text-sm-end" align-self-end>
              <v-icon
                :size="$vuetify.breakpoint.smAndUp ? 60 : 40"
                color="white"
                :class="getStatusIcon('Accepted')"
              >
                {{ getStatusIcon("Accepted") }}
              </v-icon>
              <v-badge overlap v-if="$vuetify.breakpoint.xsOnly" class="pb-7">
                <span slot="badge">{{
                  acceptedData ? acceptedData["Orders.count"] : 0
                }}</span>
              </v-badge>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-col>
    <!-- Ready -->
    <v-col cols="3" sm="6" md="3">
      <v-card
        :color="getStatusColor('Ready')"
        class="d-flex align-center p-3"
        dark
        tile
        :to="{
          name: 'active-orders',
          params: { toggle_id: getToggleId('Ready') },
        }"
      >
        <v-container grid-list-xs>
          <v-layout row wrap>
            <v-flex sm7 lg9 v-show="$vuetify.breakpoint.smAndUp">
              <v-flex xs12>
                <div class="display-1 bold">
                  <span v-if="readyData">
                    {{ readyData["Orders.count"] }}
                  </span>
                  <span v-else>0</span>
                </div>
              </v-flex>
              <v-flex xs12>
                <div class="bold">
                  {{
                    readyData
                      ? formatCurrency(readyData["Orders.lineItemTotal"])
                      : formatCurrency(0)
                  }}
                </div>
                <div class="uppercase bold">Ready</div>
              </v-flex>
            </v-flex>
            <v-flex xs12 lg3 sm5 class="text-center text-sm-end" align-self-end>
              <v-icon
                :size="$vuetify.breakpoint.smAndUp ? 60 : 40"
                color="white"
                :class="getStatusIcon('Ready')"
              >
                {{ getStatusIcon("Ready") }}
              </v-icon>
              <v-badge overlap v-if="$vuetify.breakpoint.xsOnly" class="pb-7">
                <span slot="badge">{{
                  readyData ? readyData["Orders.count"] : 0
                }}</span>
              </v-badge>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-col>
    <!-- In Transit -->
    <v-col cols="3" sm="6" md="3">
      <v-card
        :color="getStatusColor('In Transit')"
        class="d-flex align-center p-3"
        dark
        tile
        :to="{
          name: 'active-orders',
          params: { toggle_id: getToggleId('In Transit') },
        }"
      >
        <v-container grid-list-xs>
          <v-layout row wrap>
            <v-flex sm7 lg9 v-show="$vuetify.breakpoint.smAndUp">
              <v-flex xs12>
                <div class="display-1 bold">
                  <span v-if="inTransitData">
                    {{ inTransitData["Orders.count"] }}
                  </span>
                  <span v-else>0</span>
                </div>
              </v-flex>
              <v-flex xs12>
                <div class="bold">
                  {{
                    inTransitData
                      ? formatCurrency(inTransitData["Orders.lineItemTotal"])
                      : formatCurrency(0)
                  }}
                </div>
                <div class="uppercase bold">In Transit</div>
              </v-flex>
            </v-flex>
            <v-flex xs12 lg3 sm5 class="text-center text-sm-end" align-self-end>
              <v-icon
                :size="$vuetify.breakpoint.smAndUp ? 60 : 40"
                color="white"
                :class="getStatusIcon('In Transit')"
              >
                {{ getStatusIcon("In Transit") }}
              </v-icon>
              <v-badge overlap v-if="$vuetify.breakpoint.xsOnly" class="pb-7">
                <span slot="badge">{{
                  inTransitData ? inTransitData["Orders.count"] : 0
                }}</span>
              </v-badge>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
  export default {
    props: {
      resultSet: {
        type: Object,
        required: true,
      },
    },
    computed: {
      pendingData() {
        return this.getData("Pending");
      },
      acceptedData() {
        return this.getData("Accepted");
      },
      readyData() {
        return this.getData("Ready");
      },
      inTransitData() {
        return this.getData("In Transit");
      },
    },
    methods: {
      formatCurrency(text) {
        if (!text)
          return Intl.NumberFormat("en-ZA", {
            style: "currency",
            currency: "ZAR",
          }).format(0);
        return Intl.NumberFormat("en-ZA", {
          style: "currency",
          currency: "ZAR",
        }).format(parseFloat(text));
      },
      getStatusColor(status) {
        switch (status) {
          case "Pending":
            return "light-blue";
          case "Accepted":
            return "green";
          case "Ready":
            return "orange";
          case "In Transit":
            return "#33404D";
          default:
            return "pink";
        }
      },
      getStatusIcon(status) {
        switch (status) {
          case "Pending":
            return "mdi-timer-sand";
          case "Accepted":
            return "check_box";
          case "Ready":
            return "thumb_up";
          case "In Transit":
            return "mdi-truck-fast";
          default:
            return "loop";
        }
      },
      getToggleId(status) {
        switch (status) {
          case "Pending":
            return 2;
          case "Accepted":
            return 3;
          case "Ready":
            return 5;
          case "In Transit":
            return 6;
          default:
            return 2;
        }
      },
      getData(status) {
        const results = this.$props.resultSet.loadResponses[0].data;
        return results.find((element) => element["Orders.status"] == status);
      },
    },
  };
</script>

<style scoped>
  .img-responsive {
    max-width: 100%;
    vertical-align: middle;
  }
  .icon {
    max-width: 68px;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .bold {
    font-weight: bold;
  }
  .subtitle {
    color: #616d7d;
    font-size: 18px;
  }
  .v-card .material-icons.v-icon {
    font-size: 50px;
  }
  .li-wrapper div {
    display: inline-block;
    padding: 25px 16px;
    font-size: 16px;
    vertical-align: middle;
    color: #707070;
  }
  .li-wrapper.customers.active {
    cursor: pointer;
  }
  .w20 {
    width: 20%;
  }
  .w30 {
    width: 30%;
  }
  .w40 {
    width: 40%;
  }
  .w50 {
    width: 50%;
  }
  .w60 {
    width: 60%;
  }

  .mdi-timer-sand {
    animation: rotate 1.5s ease-in-out forwards 0.8s;
  }

  .check_box {
    animation: check_box 1.5s ease-in-out forwards 1.5s;
  }

  .thumb_up {
    animation: thumbs_up 1.5s ease-in-out forwards 2.2s;
  }

  .mdi-truck-fast {
    animation: transit 1.5s ease-in-out forwards 2.9s;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes check_box {
    from {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: center center;
      transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
      transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
      transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
      transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  }

  @keyframes transit {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: translateX(-10px);
    }
    50% {
      transform: rotate(2deg);
    }
    75% {
      transform: translateX(2px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes thumbs_up {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: translateY(-5px) rotate(-4deg);
    }
    75% {
      transform: translateY(0) rotate(0deg);
    }
  }
</style>
