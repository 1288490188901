




























































































































































































































































































































































import Vue, { PropType } from "vue";
import cubejs from "@cubejs-client/core";
import jwt from "jsonwebtoken";
import { QueryRenderer } from "@cubejs-client/vue";
import DashboardDataCard from "@/components/DashboardDataCard.vue";
import DashboardOrderStatusCard from "@/components/DashboardOrderStatusCard.vue";
import * as q from "@/api/cubejs";

export default Vue.extend({
  name: "DashboardOrders",

  components: { QueryRenderer, DashboardDataCard, DashboardOrderStatusCard },

  data: function () {
    const cubejsApiTokenForSupplier = jwt.sign(
      { supplierId: this.$store.state.shops.activeShop.id },
      process.env.VUE_APP_CUBEJS_API_SECRET,
      {
        expiresIn: "30d",
      }
    );
    const cubejsApiTokenForCustomer = jwt.sign(
      { customerId: this.$store.state.shops.activeShop.id },
      process.env.VUE_APP_CUBEJS_API_SECRET,
      {
        expiresIn: "30d",
      }
    );

    //  Our Cube.js Key and API URL
    const cubejsApiForSupplier = cubejs(cubejsApiTokenForSupplier, {
      apiUrl: `${process.env.VUE_APP_CUBEJS_API_URL}/cubejs-api/v1`,
    });
    const cubejsApiForCustomer = cubejs(cubejsApiTokenForCustomer, {
      apiUrl: `${process.env.VUE_APP_CUBEJS_API_URL}/cubejs-api/v1`,
    });

    return {
      cubejsApiForSupplier,
      cubejsApiForCustomer,
      // Defining Cube.js querys
      queryOrderStatus: q.supplierOrderStates,
      queryTotalOrders: q.supplierTotalOrdersPaid,
      queryUnpaid: q.supplierUnpaidOrders,
      queryPaid: q.supplierPaidOrders,
      queryTotalOrdersGraph: q.supplierTotalPaidOrdersGraph,
      queryTopCustomers: q.supplierTopPayingCustomers,
      noData: false,
      errorMessage: false,
    };
  },

  methods: {
    series(resultSet: any) {
      const seriesNames: any = resultSet.seriesNames();
      const pivot: any = resultSet.chartPivot();
      const series: any = [];
      const months: any = {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
      };
      if (seriesNames) {
        seriesNames.forEach((e: any) => {
          const data = pivot.map((p: any) => [
            `${p.x.slice(0, 4)} ${months[p.x.slice(5, 7)]}`,
            Math.round((p[e.key] + Number.EPSILON) * 100) / 100,
          ]);
          const title = e.title.replace("Order Line Item ", "Order ");
          series.push({ name: title, data });
        });
        return series;
      }
    },
    checkDashboardData(resultSet: any) {
      this.noData =
        resultSet.loadResponse &&
        resultSet.loadResponse.results[0].data.length
          ? false
          : true;
    },
    displayError() {
      this.errorMessage = true;
    },
    deepDive() {
      this.$router.push({ name: "dashboard-deep-dive" });
    },
    formatCurrency(text: string) {
      if (!text)
        return Intl.NumberFormat("en-ZA", {
          style: "currency",
          currency: "ZAR",
        }).format(0);
      return Intl.NumberFormat("en-ZA", {
        style: "currency",
        currency: "ZAR",
      }).format(parseFloat(text));
    },
  },
});
