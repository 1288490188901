<template>
  <!-- A card to display cube.js data -->
  <v-card tile class="px-4 py-2">
    <v-row>
      <v-col cols="2" v-if="iconPath">
        <img class="img-responsive icon" :src="getIcon" />
      </v-col>
      <v-col :cols="iconPath ? 10 : 12" class="text-end">
        <div class="display-1 bold">
          {{ cardData }}
        </div>
        <div class="bold uppercase subtitle">{{ title }}</div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
  export default {
    props: {
      resultSet: {
        type: Object,
        required: true,
      },
      queryKey: {
        type: Array,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      iconPath: {
        type: String,
        required: false,
      },
      currency: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      cardData() {
        // Storing cube.js query result
        const result = this.currency
          ? this.formatCurrency(
              this.resultSet.loadResponses[0].data[0][this.queryKey]
            )
          : this.resultSet.loadResponses[0].data[0][this.queryKey];
        return result;
      },
      getIcon() {
        return require(`@/assets${this.iconPath}`);
      },
    },
    methods: {
      formatCurrency(text) {
        if (!text)
          return Intl.NumberFormat("en-ZA", {
            style: "currency",
            currency: "ZAR",
          }).format(0);
        return Intl.NumberFormat("en-ZA", {
          style: "currency",
          currency: "ZAR",
        }).format(parseFloat(text));
      },
    },
  };
</script>

<style scoped>
  .img-responsive {
    max-width: 100%;
    vertical-align: middle;
  }
  .icon {
    max-width: 68px;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .bold {
    font-weight: bold;
  }
  .subtitle {
    color: #616d7d;
    font-size: 18px;
  }
  .v-card .material-icons.v-icon {
    font-size: 50px;
  }
  .li-wrapper div {
    display: inline-block;
    padding: 25px 16px;
    font-size: 16px;
    vertical-align: middle;
    color: #707070;
  }
  .li-wrapper.customers.active {
    cursor: pointer;
  }
  .w20 {
    width: 20%;
  }
  .w30 {
    width: 30%;
  }
  .w40 {
    width: 40%;
  }
  .w50 {
    width: 50%;
  }
  .w60 {
    width: 60%;
  }
</style>
