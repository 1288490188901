var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"py-2 px-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('QueryRenderer',{attrs:{"cubejs-api":_vm.cubejsApiForSupplier,"query":_vm.queryTotalPaymentsReceived},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var resultSet = ref.resultSet;
return [(loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"list-item-avatar-two-line","height":"108"}}):_c('DashboardDataCard',{attrs:{"title":"Total Payments In This Month","icon-path":"/dashboard/ic_dash_payreceived.svg","result-set":resultSet,"query-key":_vm.queryTotalPaymentsReceived.measures}})]}},{key:"error",fn:function(){return [_vm._v(" "+_vm._s(_vm.displayError())+" "),_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"list-item-avatar-two-line","height":"108"}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('QueryRenderer',{attrs:{"cubejs-api":_vm.cubejsApiForCustomer,"query":_vm.queryTotalPaymentsMadeThisMonth},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var resultSet = ref.resultSet;
return [(loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"list-item-avatar-two-line","height":"108"}}):_c('DashboardDataCard',{attrs:{"title":"Total Payments Out This Month","icon-path":"/dashboard/ic_dash_payments.svg","result-set":resultSet,"query-key":_vm.queryTotalPaymentsMadeThisMonth.measures}})]}},{key:"error",fn:function(){return [_vm._v(" "+_vm._s(_vm.displayError())+" "),_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"list-item-avatar-two-line","height":"108"}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'pt-0 pb-0' : 'pt-0 pb-0 mb-5',attrs:{"cols":"12","md":"6"}},[_c('v-btn',{staticClass:"cardButton pa-0",attrs:{"text":"","to":"/customers"}},[_c('QueryRenderer',{attrs:{"cubejs-api":_vm.cubejsApiForSupplier,"query":_vm.totalCustomers},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var resultSet = ref.resultSet;
return [_c('DashboardDataCard',{staticClass:"limegreen--text",attrs:{"title":"My Customers","icon-path":"/dashboard/ic_customers_dash.svg","result-set":resultSet,"query-key":_vm.totalCustomers.measures,"currency":false}})]}},{key:"error",fn:function(){return [_vm._v(" "+_vm._s(_vm.displayError())+" "),_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"list-item-avatar-two-line","height":"108"}})]},proxy:true}])})],1)],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6"}},[_c('v-btn',{staticClass:"cardButton pa-0",attrs:{"text":"","to":"/customers/pending"}},[_c('QueryRenderer',{attrs:{"cubejs-api":_vm.cubejsApiForCustomer,"query":_vm.totalPendingCustomers},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var resultSet = ref.resultSet;
return [(loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"list-item-avatar-two-line","height":"108"}}):_c('DashboardDataCard',{staticClass:"blue--text",attrs:{"title":"Pending Invites","icon-path":"/dashboard/ic_invites_dash.svg","result-set":resultSet,"query-key":_vm.totalPendingCustomers.measures,"currency":false}})]}},{key:"error",fn:function(){return [_vm._v(" "+_vm._s(_vm.displayError())+" "),_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"list-item-avatar-two-line","height":"108"}})]},proxy:true}])})],1)],1)],1),_c('v-card',{staticClass:"mt-5 min-450",attrs:{"tile":""}},[_c('QueryRenderer',{attrs:{"cubejs-api":_vm.cubejsApiForSupplier,"query":_vm.queryTopCustomersThisMonth},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var resultSet = ref.resultSet;
return [_c('div',{staticClass:"li-wrapper"},[_c('div',{staticClass:"w60 bold"},[_vm._v("Top Customers this Month")]),_c('div',{staticClass:"w40 text-right bold"},[_vm._v("Total")])]),_c('v-divider'),(loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"list-item-two-line, list-item-two-line, list-item-two-line,list-item-two-line"}}):(resultSet.loadResponse.results[0].data.length)?_c('div',_vm._l((resultSet.loadResponse.results[0].data),function(customer){return _c('div',{key:customer['Customers.id']},[_c('div',{staticClass:"li-wrapper customers"},[_c('div',{staticClass:"w60"},[_vm._v(_vm._s(customer["Customers.name"]))]),_c('div',{staticClass:"w40 text-right bold"},[_vm._v(" "+_vm._s(_vm.formatCurrency(customer["Payments.totalPaymentAmount"]))+" ")])]),_c('v-divider')],1)}),0):_c('div',{staticClass:"text-center pa-5"},[_vm._v(" No top customers "),_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"list-item-two-line, list-item-two-line, list-item-two-line,list-item-two-line"}})],1)]}},{key:"error",fn:function(){return [_vm._v(" "+_vm._s(_vm.displayError())+" "),_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"list-item-two-line, list-item-two-line,list-item-two-line,list-item-two-line"}})]},proxy:true}])})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{staticClass:"my-0"},[_c('v-col',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'pt-0 pb-0' : 'pt-0 pb-0 mb-5',attrs:{"cols":"12","md":"6"}},[_c('QueryRenderer',{attrs:{"cubejs-api":_vm.cubejsApiForSupplier,"query":_vm.queryTotalPaymentsReceivedToday},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var resultSet = ref.resultSet;
return [(loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"list-item-avatar-two-line","height":"108"}}):_c('DashboardDataCard',{staticClass:"green--text",attrs:{"title":"Payments in today","icon-path":"/dashboard/ic_payment_in.svg","result-set":resultSet,"query-key":_vm.queryTotalPaymentsReceivedToday.measures}})]}},{key:"error",fn:function(){return [_vm._v(" "+_vm._s(_vm.displayError())+" "),_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"list-item-avatar-two-line","height":"108"}})]},proxy:true}])})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6"}},[_c('QueryRenderer',{attrs:{"cubejs-api":_vm.cubejsApiForCustomer,"query":_vm.queryTotalPaymentsMadeToday},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var resultSet = ref.resultSet;
return [(loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"list-item-avatar-two-line","height":"108"}}):_c('DashboardDataCard',{staticClass:"red--text",attrs:{"title":"Payments out today","icon-path":"/dashboard/ic_payment_out.svg","result-set":resultSet,"query-key":_vm.queryTotalPaymentsMadeToday.measures}})]}},{key:"error",fn:function(){return [_vm._v(" "+_vm._s(_vm.displayError())+" "),_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"list-item-avatar-two-line","height":"108"}})]},proxy:true}])})],1)],1),_c('v-card',{staticClass:"mt-5 pa-5",attrs:{"tile":""}},[_c('QueryRenderer',{attrs:{"cubejs-api":_vm.cubejsApiForSupplier,"query":_vm.queryTotalPaymentsReceivedThisYearByMonth},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var resultSet = ref.resultSet;
return [(loading)?_c('v-skeleton-loader',{attrs:{"type":"image"}}):(resultSet.loadResponse.results[0].data.length)?_c('div',[_c('div',{staticClass:"title pl-1 pb-4"},[_vm._v("Payments Received per Month")]),_c('column-chart',{attrs:{"legend":false,"height":"280px","title":"","prefix":"R","colors":['#FAA225'],"data":_vm.series(resultSet),"round":"2","zeros":"true","thousands":" "}})],1):_c('v-flex',{staticClass:"text-center pa-5 echarts"},[_vm._v(" No graph data available "),_c('v-skeleton-loader',{attrs:{"type":"image"}})],1)]}}])})],1),_c('v-card',{staticClass:"mt-5 pa-5",attrs:{"tile":""}},[_c('div',{staticClass:"title pl-1 pb-4"},[_vm._v("Payments this week")]),(_vm.paymentsThisWeekGraph)?_c('line-chart',{attrs:{"legend":false,"height":"280px","prefix":"R","curve":false,"colors":['green', 'red'],"data":_vm.paymentsThisWeekGraph,"round":"2","zeros":"true","thousands":" "}}):_c('div',{staticClass:"noPayments"},[_vm._v("No graph data available")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }