






















































































































































































































































































































import Vue, { PropType } from "vue";
import cubejs from "@cubejs-client/core";
import jwt from "jsonwebtoken";
import { QueryRenderer } from "@cubejs-client/vue";
import DashboardDataCard from "@/components/DashboardDataCard.vue";
import * as q from "@/api/cubejs";

export default Vue.extend({
  name: "DashboardPayments",

  components: { QueryRenderer, DashboardDataCard },

  data: function () {
    const cubejsApiTokenForSupplier = jwt.sign(
      { supplierId: this.$store.state.shops.activeShop.id },
      process.env.VUE_APP_CUBEJS_API_SECRET,
      {
        expiresIn: "30d",
      }
    );
    const cubejsApiTokenForCustomer = jwt.sign(
      { customerId: this.$store.state.shops.activeShop.id },
      process.env.VUE_APP_CUBEJS_API_SECRET,
      {
        expiresIn: "30d",
      }
    );

    //  Our Cube.js Key and API URL
    const cubejsApiForSupplier = cubejs(cubejsApiTokenForSupplier, {
      apiUrl: `${process.env.VUE_APP_CUBEJS_API_URL}/cubejs-api/v1`,
    });
    const cubejsApiForCustomer = cubejs(cubejsApiTokenForCustomer, {
      apiUrl: `${process.env.VUE_APP_CUBEJS_API_URL}/cubejs-api/v1`,
    });

    let paymentsThisWeekGraph: any = null;

    return {
      cubejsApiForSupplier,
      cubejsApiForCustomer,
      // Defining Cube.js querys
      queryTotalPaymentsMadeThisMonth: q.totalPaymentsMadeThisMonth,
      queryTotalPaymentsMadeToday: q.totalPaymentsMadeToday,
      queryTotalPaymentsReceived: q.totalPaymentsReceivedThisMonth,
      queryTotalPaymentsReceivedToday: q.totalPaymentsReceivedToday,
      queryTopCustomersThisMonth: q.supplierTopPayingCustomersThisMonthTable,
      queryTotalPaymentsReceivedThisYearByMonth:
        q.totalPaymentsReceivedThisYearByMonth,
      totalPaymentsReceivedThisWeekByDay:
        q.totalPaymentsReceivedThisWeekByDay,
      totalPaymentsMadeThisWeekByDay: q.totalPaymentsMadeThisWeekByDay,
      totalCustomers: q.supplierConnectedCustomerCount,
      totalPendingCustomers: q.supplierPendingConnectionCustomerCount,
      paymentsThisWeekGraph,
      errorMessage: false,
    };
  },

  methods: {
    series(resultSet: any) {
      const seriesNames: any = resultSet.seriesNames();
      const pivot: any = resultSet.chartPivot();
      const series: any = [];
      const months: any = {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
      };
      if (seriesNames) {
        seriesNames.forEach((e: any) => {
          const data = pivot.map((p: any) => [
            `${p.x.slice(0, 4)} ${months[p.x.slice(5, 7)]}`,
            Math.round((p[e.key] + Number.EPSILON) * 100) / 100,
          ]);
          series.push({ name: e.title, data });
        });
        return series;
      }
    },
    seriesGraph(resultSet: any) {
      const seriesNames: any = resultSet.seriesNames();
      const pivot: any = resultSet.chartPivot();
      const series: any = [];
      if (seriesNames) {
        seriesNames.forEach((e: any) => {
          const data = pivot.map((p: any) => [
            p.x.slice(8, 10),
            Math.round((p[e.key] + Number.EPSILON) * 100) / 100,
          ]);
          series.push({ name: e.title, data });
        });
        return series;
      }
    },
    displayError() {
      this.errorMessage = true;
    },
    formatCurrency(text: string) {
      if (!text)
        return Intl.NumberFormat("en-ZA", {
          style: "currency",
          currency: "ZAR",
        }).format(0);
      return Intl.NumberFormat("en-ZA", {
        style: "currency",
        currency: "ZAR",
      }).format(parseFloat(text));
    },
  },

  mounted: async function () {
    const resultSetIn = await this.$data.cubejsApiForSupplier.load(
      this.$data.totalPaymentsReceivedThisWeekByDay
    );
    const resultSetOut = await this.$data.cubejsApiForCustomer.load(
      this.$data.totalPaymentsMadeThisWeekByDay
    );

    const received = this.seriesGraph(resultSetIn);
    const made = this.seriesGraph(resultSetOut);

    this.paymentsThisWeekGraph = [
      {
        name: "Payments Received",
        data: received[0].data,
      },
      {
        name: "Payments Made",
        data: made[0].data,
      },
    ];
  },
});
