

























































































































































































































































































































































import Vue, { PropType } from "vue";
import cubejs from "@cubejs-client/core";
import jwt from "jsonwebtoken";
import { QueryRenderer } from "@cubejs-client/vue";
import { Utils } from "@/utils/";
import DashboardDataCard from "@/components/DashboardDataCard.vue";

import * as q from "@/api/cubejs";

export default Vue.extend({
  name: "DashboardTap2Pay",

  components: { QueryRenderer, DashboardDataCard },

  data: function () {
    const cubejsApiTokenForPostgreSQL = jwt.sign(
      { orgId: this.$store.state.shops.activeShop.id },
      process.env.VUE_APP_CUBEJS_API_SECRET,
      {
        expiresIn: "30d",
      }
    );
    const cubejsApiTokenForAthena = jwt.sign(
      { orgId: this.$store.state.shops.activeShop.id, dbType: "athena" },
      process.env.VUE_APP_CUBEJS_API_SECRET,
      {
        expiresIn: "30d",
      }
    );

    //  Our Cube.js Key and API URL
    const cubejsApiForPostgreSQL = cubejs(cubejsApiTokenForPostgreSQL, {
      apiUrl: `${process.env.VUE_APP_CUBEJS_API_URL}/cubejs-api/v1`,
    });
    const cubejsApiForAthena = cubejs(cubejsApiTokenForAthena, {
      apiUrl: `${process.env.VUE_APP_CUBEJS_API_URL}/cubejs-api/v1`,
    });

    return {
      cubejsApiForPostgreSQL,
      cubejsApiForAthena,
      // Defining Cube.js querys
      queryTotalCardTransactions:
        q.tap2PayCardTransactionsTotalPaymentsInAlltime,
      queryCardTransactionsThisMonth:
        q.tap2PayCardTransactionsTotalDropsInThisMonth,
      queryT2pToday: q.tap2PayCardTransactionsTotalPaymentsInToday,
      queryT2pYesterday: q.tap2PayCardTransactionsTotalPaymentsInYesterday,
      queryLatestTransactions:
        q.tap2PayCardTransactionsTotalDropsInLatestList,
      queryTotalTransactionsPerMonthGraph:
        q.tap2PayCardTransactionsTotalDropsInThisYearByMonth,
      queryPaymentsThisWeekGraph:
        q.tap2PayCardTransactionsTotalDropsInThisWeekByDay,

      noData: false,
      errorMessage: false,
    };
  },

  methods: {
    series(resultSet: any) {
      const seriesNames: any = resultSet.seriesNames();
      const pivot: any = resultSet.chartPivot();
      const series: any = [];
      const months: any = {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
      };
      if (seriesNames) {
        seriesNames.forEach((e: any) => {
          const data = pivot.map((p: any) => [
            `${p.x.slice(0, 4)} ${months[p.x.slice(5, 7)]}`,
            Math.round((p[e.key] + Number.EPSILON) * 100) / 100,
          ]);
          series.push({ name: e.title, data });
        });
        return series;
      }
    },
    formatCurrency(text: string) {
      if (!text)
        return Intl.NumberFormat("en-ZA", {
          style: "currency",
          currency: "ZAR",
        }).format(0);
      return Intl.NumberFormat("en-ZA", {
        style: "currency",
        currency: "ZAR",
      }).format(parseFloat(text));
    },
    formatDate(item: string) {
      return Utils.formatText(item, Utils.TextType.DATE_TIME);
    },
    checkDashboardData(resultSet: any) {
      this.noData = resultSet.loadResponse.results[0].data.length
        ? false
        : true;
    },
    displayError() {
      this.errorMessage = true;
    },
  },
});
