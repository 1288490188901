<template>
  <v-layout>
    <v-stepper non-linear>
      <v-stepper-header class="small-stepper">
        <v-stepper-step editable step="1" @click="vstep = 1"
          >Payments</v-stepper-step
        >

        <v-stepper-step editable step="2" @click="vstep = 2"
          >Orders</v-stepper-step
        >

        <v-stepper-step editable step="3" @click="vstep = 3"
          >Tap2Pay</v-stepper-step
        >

        <v-stepper-step editable step="4" @click="vstep = 4"
          >Safes</v-stepper-step
        >
      </v-stepper-header>

      <v-stepper-content step="1">
        <DashboardPayments v-if="vstep == 1" />
      </v-stepper-content>

      <v-stepper-content step="2">
        <DashboardOrders v-if="vstep == 2" />
      </v-stepper-content>

      <v-stepper-content step="3">
        <DashboardTap2Pay v-if="vstep == 3" />
      </v-stepper-content>

      <v-stepper-content step="4">
        <DashboardSafes v-if="vstep == 4" />
      </v-stepper-content>
    </v-stepper>
  </v-layout>
</template>

<script>
  import Vue from "vue";
  import { Utils } from "@/utils/";
  import DashboardPayments from "@/views/dashboard/DashboardPayments.vue";
  import DashboardOrders from "@/views/dashboard/DashboardOrders.vue";
  import DashboardTap2Pay from "@/views/dashboard/DashboardTap2Pay.vue";
  import DashboardSafes from "@/views/dashboard/DashboardSafes.vue";

  export default Vue.extend({
    name: "Dashboard",

    components: {
      DashboardPayments,
      DashboardOrders,
      DashboardTap2Pay,
      DashboardSafes,
    },

    data: function () {
      return {
        vstep: 1,
      };
    },

    methods: {
      async mounted() {
        this.$store.dispatch("orders/fetchOrderStatusses");
      },
      formatCurrency(item) {
        return Utils.formatText(item, Utils.TextType.CURRENCY);
      },
      getOrderStatusById(statusId) {
        for (let status in this.activeOrdersStatus) {
          if (this.activeOrdersStatus[status].id === statusId)
            return this.activeOrdersStatus[status];
        }
        // This will most likely not happen
        return this.activeOrdersStatus[0];
      },
      getStatusColor(status) {
        switch (status) {
          case "Pending":
            return "light-blue";
          case "Accepted":
            return "green";
          case "Ready":
            return "orange";
          case "In Transit":
            return "#33404D";
          default:
            return "pink";
        }
      },
      getStatusIcon(status) {
        switch (status) {
          case "Pending":
            return "mdi-timer-sand";
          case "Accepted":
            return "check_box";
          case "Ready":
            return "thumb_up";
          case "In Transit":
            return "mdi-truck-fast";
          default:
            return "loop";
        }
      },
      getStatusTotal(statusId) {
        let total = 0;

        this.ordersByStatus(statusId).forEach((order) => {
          order.items.forEach((item) => {
            total = total + parseFloat(item.price);
          });
        });

        return this.formatCurrency(total.toString());
      },
      getToggleId(status) {
        switch (status) {
          case "Pending":
            return 2;
          case "Accepted":
            return 3;
          case "Ready":
            return 5;
          case "In Transit":
            return 6;
          default:
            return 2;
        }
      },
    },
  });
</script>

<style>
  .img-responsive {
    max-width: 100%;
    vertical-align: middle;
  }

  .icon {
    max-width: 68px;
  }

  .uppercase {
    text-transform: uppercase;
  }
  .bold {
    font-weight: bold;
  }
  .subtitle {
    color: #616d7d;
    font-size: 18px;
  }
  .v-card .material-icons.v-icon {
    font-size: 50px;
  }

  .li-wrapper div {
    display: inline-block;
    padding: 25px 16px;
    font-size: 16px;
    vertical-align: middle;
    color: #707070;
  }
  .li-wrapper.customers.active {
    cursor: pointer;
  }

  .w20 {
    width: 20%;
  }

  .w30 {
    width: 30%;
  }

  .w40 {
    width: 40%;
  }

  .w50 {
    width: 50%;
  }

  .w60 {
    width: 60%;
  }

  .echarts {
    height: 562px;
  }

  .mdi-timer-sand {
    animation: rotate 1.5s ease-in-out forwards 0.8s;
  }

  .check_box {
    animation: check_box 1.5s ease-in-out forwards 1.5s;
  }

  .thumb_up {
    animation: thumbs_up 1.5s ease-in-out forwards 2.2s;
  }

  .mdi-truck-fast {
    animation: transit 1.5s ease-in-out forwards 2.9s;
  }

  .floating {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .min-450 {
    min-height: 450px;
  }

  .v-stepper {
    width: 100%;
  }

  .v-stepper .v-stepper__content {
    padding: 0;
  }

  .small-stepper {
    height: auto;
    background-color: #fff;
    position: relative;
    z-index: 1;
  }

  .red--text img,
  .green--text img,
  .limegreen--text img {
    margin: 50% auto 0;
  }

  .limegreen--text {
    color: limegreen !important;
  }

  .limegreen--text img {
    max-height: 45px;
  }

  .blue--text img {
    max-height: 52px;
    margin: 30% auto 0;
  }

  .noPayments {
    min-height: 102px;
  }

  .cardButton {
    height: auto !important;
    width: 100%;
    min-height: 107px;
  }

  .cardButton .v-btn__content > div {
    width: 100%;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes check_box {
    from {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: center center;
      transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
      transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
      transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
      transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  }

  @keyframes transit {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: translateX(-10px);
    }
    50% {
      transform: rotate(2deg);
    }
    75% {
      transform: translateX(2px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes thumbs_up {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: translateY(-5px) rotate(-4deg);
    }
    75% {
      transform: translateY(0) rotate(0deg);
    }
  }
</style>
